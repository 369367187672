body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  background: var(--background-default);
}

a {
  color: var(--tg-theme-link-color);
  text-decoration: none;
}

*, *:hover, *:active, *:focus {
  outline: 0 !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
}

//.Fade-enter {
//  opacity: 0;
//  z-index: 1;
//  transform: translateY(100%);
//  transition: 250ms;
//}
//
//.Fade-enter.Fade-enter-active {
//  opacity: 1;
//  transition: opacity 250ms ease-in;
//}
//
//.Fade-exit {
//  //opacity: 0;
//  z-index: 1;
//  transform: translateY(0);
//  transition: 250ms;
//}
//
//.Fade-exit.Fade-exit-active {
//  //opacity: 0;
//  transform: translateY(100%);
//  transition: opacity 250ms ease-in;
//}
//
//.Fade-enter-done {
//  transform: translateY(0);
//  transition: 500ms;
//  //opacity: 0;
//}

:root {
  --duration: 0.7s;

  //--tg-color-scheme: light;
  //--tg-theme-bg-color: #ffffff;
  //--tg-theme-text-color: #000000;
  //--tg-theme-hint-color: #707579;
  //--tg-theme-link-color: #3390ec;
  //--tg-theme-button-color: #3390ec;
  //--tg-theme-button-text-color: #ffffff;

  --background-default: var(--tg-theme-bg-color);
  --background-secondary: var(--tg-theme-secondary-bg-color);
  --verify-icon: var(--tg-theme-link-color);
  --spinner: #99A2AD;
  --button-active: #e3e2e9;
  //--text-primary: var(--tg-theme-text-color);
  --text-primary: #000;
  --text-secondary: #6D7885;
  --text-login-secondary: #4D4747;
  --search-bg-color: #EFEEF3;
  --search-bg-color2: #FFFFFF;
  --search-placeholder: #8E8D92;
  --skeleton: #EFEFF3;
  --skeleton2: #E3E3E7;
  --bg-gradient: linear-gradient(180deg, #FFFFFF 0%, #EFEEF3 84.06%) #EFEEF3;
  --cell-bg: #FFFFFF;
  --cell-bg-active: #e3e2e9;
  --cell-border: #C7C6CB;
  --cell-right-arrow: #BAB9BE;
  --nft-bg: #F8F8FB;
  --hcell-bg-active: #F7F7F9;
  --mini-menu-cell: #FFFFFF;
  --mini-menu-cell-active: #e3e2e9;
  --mini-menu-separator: #E2E2E4;
  --mini-menu-sm-separator: #E2E2E4;
  --mini-menu-top: #99989F;
  --right-cell-info: #8E8D92;
  --nftpage-top: var(--tg-theme-bg-color);
  --nftpage-bottom: #EFEEF3;
  --tabs-text: #000000;
  --tabs-bg: rgba(118, 118, 128, 0.12);
  --tabs-bg-active: #FFFFFF;
  --input-bg: #FFFFFF;

  --white: #FFFFFF;
  --blue: #007AFE;

  --lg0: #EFEEF3;
  --lg1: #FFFFFF;
}

//@mixin background {
//  background: #EFEEF3 linear-gradient(180deg, #FFFFFF 0%, #EFEEF3 84.06%) no-repeat;
//  background-size: 100% 100vh;
//
//  body[color-scheme="dark"] {
//    background: #000 linear-gradient(180deg, var(--tg-theme-bg-color) 0%, #000000 100%) no-repart;
//    //background: #EFEEF3 linear-gradient(180deg, #FFFFFF 0%, #EFEEF3 84.06%) no-repeat;
//  }
//}

body[color-scheme="dark"] {
  --spinner: #59595B;
  --text-primary: #fff;
  --text-secondary: #99989F;
  --text-login-secondary: #99989F;
  --search-bg-color: #0F0F0F;
  --search-bg-color2: #1C1C1E;
  --search-placeholder: #8F8F8F;
  --skeleton: #323233;
  --skeleton2: #1E1D1D;
  --bg-gradient: linear-gradient(180deg, var(--tg-theme-bg-color) 0%, #000000 100%) #000;
  --cell-bg: #1C1C1E;
  --cell-bg-active: #313035;
  --cell-border: #3D3C41;
  --cell-right-arrow: #5B5B5D;
  --nft-bg: #222223;
  --hcell-bg-active: #2A2A2C;
  --mini-menu-cell: #232323;
  --mini-menu-cell-active: #424244;
  --mini-menu-separator: #1C1C1C;
  --mini-menu-sm-separator: #414141;
  --mini-menu-top: #99989F;
  --right-cell-info: #8E8D92;
  --nftpage-top: var(--tg-theme-bg-color);
  --nftpage-bottom: #000000;
  --tabs-text: #FFFFFF;
  --tabs-bg: rgba(118, 118, 128, 0.24);
  --tabs-bg-active: #636366;
  --input-bg: #1C1C1E;

  --lg0: #000;
  --lg1: var(--tg-theme-bg-color);
}

@mixin background {
  //background: var(--lg0) linear-gradient(180deg, var(--lg1) 0%, var(--lg0) 84.06%) no-repeat;
  //background-size: 100% 100vh;
  background-color: var(--background-secondary);

  &::after {
    content: "";
    display: block;
    background-color: var(--nftpage-bottom);
    position: fixed;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
  }
}
