.App {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__gem {
    display: inline-block;
    width: 182px;
    height: 156px;
    background-image: url('./gems-logo.png');
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__center {
    padding: 15px;

    h1 {
      margin-top: 20px;
      color: var(--text-primary);
      font-size: 28px;
      font-weight: 600;
      line-height: 33px;
      text-align: center;
      margin-bottom: 10px;
      padding: 0;
    }

    p {
      color: var(--text-secondary);
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
    }
  }

}

body,html,#root {
  height: 100%;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
